import React from "react";
import style from './imageItem.module.scss';

type ImageItemPropsType = {
    image: string,
    width: number,
    height: number,
    name: string,
    degrees: number
}
const ImageItem = ({image, width, height, name, degrees}: ImageItemPropsType) => {
    const style = {
        display: "block",
        width: `${width}rem`,
        height: `${height}rem`,
        transform: `rotate(${degrees}deg)`
    }

    return(
        <img src={image} style={style} alt={name}/>
    )
}

export default ImageItem;