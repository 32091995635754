import React from "react";
import style from './sectionDevelopers.module.scss';
import Title from "../parts/title/title";
import SectionDescription from "../parts/sectionDescription/sectiondescription";
import ExpertItem from "./expertItem/expertItem";
import ImageBlock from "./imageBlock/imageBlock";

const SectionDevelopers = () => {
    const textDescription = `Over 90% of our team are software developers and engineers. With access to a pool of top 1% talented individuals from Eastern Europe, we can quickly scale our team to meet your demands.`
    const expertItems = [
        {
            id: 1,
            title: 'System architecture',
            text: `We design the structures and interactions of software components, ensuring efficient, 
            scalable, and maintainable systems while making key technology and design decisions.`
        },
        {
            id: 2,
            title: 'Backend development',
            text: `We focus on creating and maintaining the server-side logic together with data base infrastructure
             and dependencies of software applications. Key elements wa are concentrating on is speed and and performance of the code.`
        },
        {
            id: 3,
            title: 'Frontend development',
            text: `We specialize in crafting the user interface and client-side functionalities of software applications. 
            We can build robust client panels and integrate complex functionality in small, fast and stable fronted code.`
        },
        {
            id: 4,
            title: 'Custom API integration',
            text: `We've seamlessly connect diverse software systems, enabling them to communicate effortlessly and exchange 
            vital data. This not only streamlines operations but also unlocks new possibilities for innovation and efficiency.`
        },
        {
            id: 5,
            title: 'UI/UX design',
            text: `We've put the user at the heart of our digital products, ensuring that every interaction is intuitive and
             engaging. It's a creative journey that balances aesthetics and functionality, resulting in designs that users love. `
        },
        {
            id: 6,
            title: 'System administration',
            text: `From maintaining servers to optimizing network performance, We are committed to keeping our systems running smoothly. 
            It's all about enabling our teams to focus on their core tasks with confidence in our dependable IT foundation.`
        }
    ]

    return (
        <section id={'Our developers'} className={`${style.section_developers} relative_position`}>
            <Title color={'#E7E7E7'} text={'Our developers'}/>
            <SectionDescription text={textDescription} color={'#E7E7E7'}/>
            <div className={style.info_wrapper}>
                <div className={style.info_wrapper_text}>We work with technologies such as
                    <span>Java, React, Node.js, .NET, Angular, AWS, Azure, Gitlab, Atlassian</span> products and others.
                    Our team is also experienced in providing migration from different legacy systems.
                </div>
                <h3>Our area of expertise includes but is not limited to:</h3>
                {expertItems.map(item => <ExpertItem key={item.id} text={item.text} title={item.title}/>)}
            </div>
            <ImageBlock/>
        </section>
    )
}

export default SectionDevelopers;