import React from "react";
import style from './arrowItem.module.scss';
import arrow from './../../../assets/images/how_we_work_section/arrow_right.png';

const ArrowItem = () => {

    return(
        <div className={style.arrow_item}>
            <img src={arrow} alt="arrow_right"/>
        </div>
    )
}

export default ArrowItem;