import React, {useContext, useState} from "react";
import style from './header.module.scss';
import logo from './../../assets/images/logo.png';
import Navigation from "./navigation/navigation";
import Menu from "../menu/menu";
import Burger from "../menu/burger/burger";
import {useMobileMin} from "../../hooks/useAdaptives";
import {Link} from "react-scroll";
import LinkItem from "./navigation/linkItem/linkItem";
import {v1} from "uuid";
import {NavLink, useLocation} from "react-router-dom";

const Header = () => {
    const [lang, setLang] = useState<'en' | 'pl'>('en');

    let location = useLocation();
    let isBlogRoute = location.pathname.includes('blog');

    const isMinMobile = useMobileMin();

    return (
        <header className={style.header}>
            <div className={style.block_with_contact}>
                {isMinMobile &&
                    <>
                        <Burger/>
                        <Menu/>
                    </>}
                <div className={style.contact_part}>Start your project today. Call us:
                    <span className={style.active}> +1 813 804 8913</span>
                </div>
                {/*<div className={style.locale_part}>*/}
                {/*    <span className={lang === 'en' ? style.active_locale : ''}*/}
                {/*          onClick={() => setLang('en')}>EN</span> | <span*/}
                {/*    className={lang === 'pl' ? style.active_locale : ''} onClick={() => setLang('pl')}>PL</span>*/}
                {/*</div>*/}
            </div>

            <div className={style.block_with_logo}>
                <div className={style.logo}>
                    <img src={logo} alt="logo"/>
                </div>
                <div className={style.company_name}>Bright<span>Dot</span></div>
                {!isMinMobile && !isBlogRoute && <Navigation />}
                {/*<button className={style.contact_button}>Contact us</button>*/}
                {/*<Link className={style.contact_button}*/}
                {/*      to={'Contacts'}*/}
                {/*      spy={true}*/}
                {/*      smooth={true}*/}
                {/*      offset={-120}*/}
                {/*      duration={500}>{'Contact us'}</Link>*/}

                {/*{path === '/blog' && <span>Home</span>}*/}
                {isBlogRoute && !isMinMobile && <div className={style.home_link}>
                    <NavLink to={'/'} >Home</NavLink>
                </div>}

                {!isBlogRoute && <LinkItem link={{id: v1(), linkName: 'Contact us', linkTo: 'Contacts'}}
                          classN={style.contact_button}/>}
            </div>
        </header>
    )
}

export default Header;