import React from "react";
import style from './imageBlock.module.scss';
import angular_icon from './../../../assets/images/developers_section/angular_icon.png';
import react_icon from './../../../assets/images/developers_section/react_icon.png';
import js_icon from './../../../assets/images/developers_section/js_icon.png';
import java_icon from './../../../assets/images/developers_section/java_icon.png';
import tr_icon from './../../../assets/images/developers_section/tr_icon.png';
import aws_icon from './../../../assets/images/developers_section/aws_icon.png';
import fox_icon from './../../../assets/images/developers_section/fox_icon.png';
import ImageItem from "./imageItem/imageItem";
import {useLaptop, useMobile, useMobileMin} from "../../../hooks/useAdaptives";

const ImageBlock = () => {

    const isLaptop = useLaptop();
    const isMobile = useMobile();
    const isMinMobile = useMobileMin();
    const minImage = isLaptop || isMobile || isMinMobile;

    return (
        <div className={style.image_block_wrapper}>
            <div className={style.circle_1}></div>

            <div className={style.circle_2}>
                <div className={`${style.item_with_icon_orange} ${style.orange_rotate}`}></div>
                <div className={`${style.item_with_icon_angular} ${style.angular_rotate} ${style.flex}`}>
                    <ImageItem image={angular_icon} width={minImage ? 1.925 : 2.75} height={minImage ? 2.0125 : 2.875}
                               name={'angular_icon'} degrees={230}/>
                </div>
            </div>

            <div className={style.circle_3}>
                <div className={`${style.item_with_icon_js} ${style.js_rotate} ${style.flex}`}>
                    <ImageItem image={js_icon} width={minImage ? 1.925 : 2.75} height={minImage ? 2.1 : 3}
                               name={'js_icon'} degrees={55}/>
                </div>
                <div className={`${style.item_with_icon_aws} ${style.aws_rotate} ${style.flex}`}>
                    <ImageItem image={aws_icon} width={minImage ? 2.975 : 4.25} height={minImage ? 1.7941 : 2.563}
                               name={'aws_icon'} degrees={-60}/>
                </div>
                <div className={`${style.item_with_icon_react} ${style.react_rotate} ${style.flex}`}>
                    <ImageItem image={react_icon} width={minImage ? 2.975 : 4.25} height={minImage ? 2.2281 : 3.813}
                               name={'react_icon'} degrees={20}/>
                </div>
            </div>

            <div className={style.circle_4}>
                <div className={`${style.item_with_icon_tr} ${style.tr_rotate} ${style.flex}`}>
                    <ImageItem image={tr_icon} width={minImage ? 1.925 : 2.75} height={minImage ? 1.4875 : 2.125}
                               name={'tr_icon'} degrees={0}/>
                </div>
                <div className={`${style.item_with_icon_java} ${style.java_rotate} ${style.flex}`}>
                    <ImageItem image={java_icon} width={minImage ? 1.3125 : 1.875} height={minImage ? 2.2316 : 3.188}
                               name={'java_icon'} degrees={235}/>
                </div>
                <div className={`${style.item_with_icon_fox} ${style.fox_rotate} ${style.flex}`}>
                    <ImageItem image={fox_icon} width={minImage ? 1.925 : 2.75} height={minImage ? 1.75 : 2.5}
                               name={'fox_icon'} degrees={110}/>
                </div>
            </div>

        </div>
    )
}

export default ImageBlock;