import React from "react";
import style from './technology.module.scss';

type TechnologyPropsType = {
    name: string
}
const Technology = ({name}: TechnologyPropsType) => {

    return(
        <div className={style.technology}>{name}</div>
    )
}

export default Technology;