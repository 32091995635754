import React from "react";
import style from './sectionAnalysis.module.scss';
import Title from "../parts/title/title";
import Slider from "react-slick";
import CustomSlide from "./customSlide/customSlide";
import ver1 from './../../assets/images/analysis/1/ver_1.png';
import ver2 from './../../assets/images/analysis/1/ver_2.png';
import ver3 from './../../assets/images/analysis/1/ver_3.png';
import unb1 from './../../assets/images/analysis/2/unboXt 1.png';
import unb2 from './../../assets/images/analysis/2/unboXt 2.png';
import unb3 from './../../assets/images/analysis/2/unboXt 3.png';
import sir1 from './../../assets/images/analysis/3/sir1.png';
import sir2 from './../../assets/images/analysis/3/sir2.png';
import sir3 from './../../assets/images/analysis/3/sir3.png';

import NextArrow from "./nextArrow/nextArrow";
import PrevArrow from "./prevArrow/prevArrow";
import Particle from "../practicleTest/testPract";

export type SlideType = {
    title: string,
    client: string,
    description: string,
    team: Array<string>,
    technologies: Array<string>,
    fotos: Array<{id: number, smallImage: string, largeImage: string}>
}
const SectionAnalysis = () => {
    const settings = {
        className: style.custom_style,
        dots: true,
        infinite: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        adaptiveHeight: true,
        appendDots: (dots: any) => (
            <div
                style={{
                    // backgroundColor: "#ddd",
                    // borderRadius: "10px",
                    // padding: "10px"
                    //marginTop: '-3rem'
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        // customPaging: (i: any) => (
        //     <div
        //         style={{
        //             width: "10px",
        //             height: '10px',
        //             color: "blue",
        //             borderRadius: '50%',
        //             border: "1px blue solid"
        //         }}
        //     ></div>
        // ),
    };

    const allSlides: Array<SlideType> = [{
        title: 'AI driven stewardship solution',
        client: 'Verity Platforms Inc ',
        description: `Platform designed by former Asset Manager with 16 years of experience. Objective was to 
        create the only AI infused end-to-end stewardship solution for Investors, Asset Managers,
         NGOs and others.  Platform incorporates:  - Engagement management, tracking, and auditable reporting -
          Primary source data and research - Collaboration across teams and coalitions - AI integration finetuned for the enterprise`,
        team: ['Backend Developer' , 'Frontend Developer', 'Full-Stack Developer', 'Project Manager', 'QA Engineer'],
        technologies: ['JavaScript (React)', 'PHP', 'Node.js', 'PostgreSQL'],
        fotos: [
            {id: 1, smallImage: ver1, largeImage: ver1},
            {id: 2, smallImage: ver2, largeImage: ver2},
            {id: 3, smallImage: ver3, largeImage: ver3}
        ]
    },
        {
            title: 'Platform supporting managers in their leadership role',
            client: 'unboXt Inc',
            description: `Project was to build an ultimate leadership toolkit designed to help managers understand what 
            makes their employees tick. System helps the operators to analyze sentiment and motivation of team members. 
            It has build in coaching functionality and metrics gathering system.`,
            team: ['Backend Developer' , 'Frontend Developer', 'Full-Stack Developer','QA Engineer'],
            technologies: ['Node.js', 'React', 'PostgreSQL'],
            fotos: [
                {id: 1, smallImage: unb1, largeImage: unb1},
                {id: 2, smallImage: unb2, largeImage: unb2},
                {id: 3, smallImage: unb3, largeImage: unb3}
            ]
        },

        {
            title: 'AI tool for industrial diagrams',
            client: 'DataSeer Inc',
            description: `AI tool for industrial diagrams. DataSeer provides automated visualization software that digitizes unstructured 
            data trapped in 2D images and creates a digital twin database with API access. Integrating industrial engineering with Machine Learning and Computer Vision. `,
            team: ['2 x Frontend Developer'],
            technologies: ['React'],
            fotos: [
                {id: 1, smallImage: sir1, largeImage: sir1},
                {id: 2, smallImage: sir2, largeImage: sir2},
                {id: 3, smallImage: sir3, largeImage: sir3}
            ]
        },
    ]

    return(
        <section id={'Case analysis'} className={`${style.section_analysis} relative_position`}>
            <Particle  id={'part_2'}/>
            <Title color={'white'} text={'Case analysis'}/>
            <div className={style.slider_wrapper}>
                <Slider {...settings}>
                    {allSlides.map((s, index) => <CustomSlide key={index} index={index+1} allSlide={allSlides.length} slide={s}/>)}
                </Slider>
            </div>
        </section>
    )
}

export default SectionAnalysis;