import React from "react";
import style from './sectionContacts.module.scss';
import Title from "../parts/title/title";
import map from './../../assets/images/maps (1).png';
import BlockWithContacts from "./blockWithContacts/blockWithContacts";
import BlockWithForm from "./blockWithForm/blockWithForm";

const SectionContacts = () => {

    return (
        <section id={'Contacts'} className={`${style.section_contacts} relative_position`}>
            <Title color={'white'} text={'You’ve come this far. Let\'s chat'}/>
            <div className={style.wrapper_info}>
                <div className={style.map_image}>
                    <img src={map} alt="map"/>
                    <BlockWithContacts/>
                </div>
            </div>
            <BlockWithForm/>
        </section>
    )
}

export default SectionContacts;