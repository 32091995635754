import React from "react";
import style from './footer.module.scss';
import linkedin from './../../assets/images/linkedin.png';
import facebook from './../../assets/images/facebook.png';

const Footer = () => {

    return (
        <footer className={style.footer}>
            <div className={style.footer_cent}>
                <div className={style.year}>© {new Date().getFullYear()} by BrightDot</div>
                <div className={style.icons}>
                    <div className={style.linkedin}><img src={linkedin} alt="linkedin_icon"/></div>
                    <div className={style.facebook}><img src={facebook} alt="facebook_icon"/></div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;