import { useMediaQuery } from 'react-responsive'

export const useDesktop = (): boolean => useMediaQuery({ minWidth: 1480 })

export const useDesktopLaptop = (): boolean =>
    useMediaQuery({ minWidth: 1201, maxWidth: 1479 })

export const useLaptop = (): boolean =>
    useMediaQuery({ minWidth: 834, maxWidth: 1200 })

export const useMobile = (): boolean =>
    useMediaQuery({ maxWidth: 833 })

export const useMobileMin = (): boolean =>
    useMediaQuery({ maxWidth: 668 })

export const useLandscape = (): boolean =>
    useMediaQuery({ query: '(orientation: landscape)' })

export const useCommentSlider = (): boolean =>
    useMediaQuery({ maxWidth: 970 })

export const useCommentSliderMini = (): boolean =>
    useMediaQuery({ maxWidth: 480 })