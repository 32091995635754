import React from "react";
import style from './sectionHowWork.module.scss';
import Title from "../parts/title/title";
import ItemStep from "./itemStep/itemStep";
import ItemWork from "./itemWork/itemWork";
import businesAnalysisIcon from './../../assets/images/how_we_work_section/busines_analysis.png';
import estimationIcon from './../../assets/images/how_we_work_section/estimation.png';
import teamIcon from './../../assets/images/how_we_work_section/team.png';
import developmentIcon from './../../assets/images/how_we_work_section/development.png';
import OnboardingItem from "./onboardingItem/onboardingItem";
import ArrowItem from "./arrowItem/arrowItem";
import {v1} from "uuid";

const SectionHowWork = () => {

    const steps = [
        {id: 1, text: 'To effectively work with our clients we utilize tools such as Jira, Bitbucket, Slack, etc.'},
        {
            id: 2,
            text: 'Depending on your needs we can concentrate on solving specific tickets and implementing new functionalities or we can design the architecture and build the entire product.'
        },
        {
            id: 3,
            text: 'By carefully analyzing and defining your business needs and requirements we can design the entire product infrastructure and manage production.'
        }
    ]

    const workItems = [
        {
            id: 1,
            title: 'Developers as an expansion of your team',
            mainText: `In this model, we support your team with 
        carefully selected developers and technical expertise. Our team operates as part of your team, located in a different 
        office. If needed our developers participate in your daily sprint meetings to work as a team and accomplish your 
        business objectives.`,
            addText: `We use tools like Jira, Bitbucket, Slack, Zoom, etc to schedule regular sprints and finalize the production.`
        },
        {
            id: 2,
            title: 'A dedicated team with production management',
            mainText: `We take care of the entire production part.
         Our team of developers works with a dedicated project manager. This person is the main contact that coordinates the 
         entire process. With our experience, we can provide you with support on how to advance your business model into a 
         working product.`,
            addText: `We can build an enterprise-level solution or a simple MVP that is ready to be scaled in the future.`
        }
    ]

    const onboardingItems = [
        {icon: businesAnalysisIcon, number: 1, title: 'Busines analysis'},
        {icon: estimationIcon, number: 2, title: 'Initial project requirements estimation'},
        {icon: teamIcon, number: 3, title: 'Team set up'},
        {icon: developmentIcon, number: 4, title: 'Development proces'}
    ]

    return (
        <section id={'How we work'} className={`${style.section_how_work} relative_position`}>
            <div className={style.section_how_work_dark_part}>
                <Title color={'#E7E7E7'} text={'How we work'} margin={'1rem 0 3.125rem 0'}/>
                <div className={style.cent}>
                    <div className={style.steps_wrapper}>
                        {steps.map(step => <ItemStep key={step.id} text={step.text}/>)}
                    </div>
                    <h3>Whatever services you require, we have two models of collaboration:</h3>
                </div>
                <div className={style.cards_wrapper}>
                    {workItems.map(item => <ItemWork key={item.id} id={item.id} title={item.title} addText={item.addText}
                                                     mainText={item.mainText}/>)}
                </div>
            </div>

            <div className={style.section_how_work_onboarding}>
                <h3>Onboarding</h3>
                <div className={style.onboarding_item_wrapper}>
                    {onboardingItems.map((item, index, arr) => {
                        if (index + 1 === arr.length) {
                            return <OnboardingItem key={v1()} number={item.number} title={item.title} icon={item.icon}/>

                        } else {
                            return <React.Fragment key={v1()}>
                                <OnboardingItem number={item.number} title={item.title} icon={item.icon}/>
                                <ArrowItem />
                            </React.Fragment>
                        }
                    })}
                </div>
            </div>

        </section>
    )
}

export default SectionHowWork;