import React from "react";
import style from './commentItem.module.scss';
import {useCommentSlider} from "../../../hooks/useAdaptives";

type CommentItemPropsType = {
    index: number,
    text: string,
    userFoto: string,
    userName: string,
    userPosition: string
}
const CommentItem = ({text, userFoto, userName, userPosition}: CommentItemPropsType) => {
    const isChangeSlide = useCommentSlider();

    return(
        <div className={style.comment_item}>
            <div className={style.text}>{text}</div>
            <div className={style.user_info}>
                <div className={style.user_info_foto}>
                    <img src={userFoto} alt=""/>
                </div>
                <div className={style.user_info_data}>
                    <p className={style.name}>{userName}</p>
                    <p className={style.position}>{userPosition}</p>
                </div>
            </div>
        </div>
    )
}

export default CommentItem;