import React from "react";
import style from './prevArrow.module.scss';
import left_arrow from './../../../assets/images/analysis/prev.png';

const PrevArrow = (props: any) => {

    return(
        <div className={style.prev_arrow} onClick={props.onClick}>
            <img src={left_arrow} alt="prev_arrow"/>
        </div>
    )
}

export default PrevArrow;