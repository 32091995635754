import React, {useContext} from "react";
import style from './burger.module.scss';
import {MenuContext} from "../../../hooks/context";

const Burger = () => {

    const {isOpen, openMenu} = useContext(MenuContext);

    return(
        <div className={`${style.burger} ${isOpen ? style.shown : style.hide}`}
             onClick={() => openMenu(!isOpen)}
             onTouchStart={() => openMenu(!isOpen)}>
            {[1,2,3].map((d, index) => <div key={index}
                                            className={`${isOpen ? style.shown_burger_line : style.hide_burger_line}`}
                                            style={{backgroundColor: isOpen ? '#0a0a0a' : '#ff6600'}}></div>)}
        </div>
    )
}

export default Burger;