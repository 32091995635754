import React from "react";
import style from './onboardingItem.module.scss';

type OnboardingItemPropsType = {
    icon: string,
    number: number,
    title: string
}
const OnboardingItem = ({icon, number, title}: OnboardingItemPropsType) => {

    return(
        <div className={style.item_wrapper}>
            <div className={style.icon}><img src={icon} alt="image"/></div>
            <div className={style.title}>{title}</div>
            <div className={style.step_number}>{number}</div>
        </div>
    )
}

export default OnboardingItem;