import React from "react";
import style from './logoItem.module.scss';

type LogoItemPropsType = {
    id: string,
    width: number,
    height: number,
    logo: any
}
const LogoItem = ({id, width, height, logo}: LogoItemPropsType) => {
    const styleFromProps = {
        width: `${width}rem`,
        height: `${height}rem`,
    }

    return(
        <div className={style.logo_item} >
            <img src={logo} alt="logo" style={styleFromProps} />
        </div>
    )
}

export default LogoItem;