import React, {useContext, useRef} from "react";
import style from './menu.module.scss';
import {Link} from "react-scroll";
import {MenuContext} from "../../hooks/context";
import {useOnClickOutside} from "../../hooks/useOnClickMenu";
import {NavLink, useLocation} from "react-router-dom";

const Menu = () => {
    const {isOpen, openMenu} = useContext(MenuContext);

    let location = useLocation();
    let isBlogRoute = location.pathname.includes('blog');

    const node = useRef<HTMLDivElement>(null);
    useOnClickOutside(node, () => openMenu(false));

    const links = [
        {id: 1, linkName: 'What we do'},
        {id: 2, linkName: 'Our developers'},
        {id: 3, linkName: 'How we work'},
        {id: 4, linkName: 'Who we work for'},
        {id: 5, linkName: 'Case analysis'},
        {id: 6, linkName: 'Testimonials'},
        // {id: 7, linkName: 'Blog'},
    ]

    return (
        <div className={`${style.menu} ${isOpen ? style.shown : style.hide}`} ref={node}>
            {!isBlogRoute ? links.map(link => {
                return (
                    <div className={style.link_wrapper} key={link.id}>
                        {link.linkName !== 'Blog' ? <Link activeClass={style.active}
                              onClick={() => openMenu(false)}
                              className={style.link}
                              to={link.linkName}
                              spy={true}
                              smooth={true}
                              offset={-120}
                              duration={500}>{link.linkName}</Link>
                            : <NavLink to={'/blog'}
                                       className={style.link}
                                       onClick={() => openMenu(false)}>Blog</NavLink>
                        }
                    </div>
                )
            }) : <div className={style.link_wrapper}>
                <NavLink to={'/'} onClick={() => openMenu(false)}>Home</NavLink>
            </div>
            }
        </div>
    )
}

export default Menu;