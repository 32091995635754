import React, {useEffect} from "react";
import style from './main.module.scss';
import Banner from "../banner/banner";
import WhatDo from "../sectionWhatDo/sectionWhatDo";
import SectionDevelopers from "../sectionDevelopers/sectionDevelopers";
import SectionHowWork from "../sectionHowWork/sectionHowWork";
import SectionWhoWeWork from "../sectionWhoWeWork/sectionWhoWeWork";
import SectionAnalysis from "../sectionAnalysis/sectionAnalysis";
import SectionTestimonials from "../sectionTestimonials/sectionTestimonials";
import SectionBlog from "../sectionBlog/sectionBlog";
import SectionContacts from "../sectionContacts/sectionContacts";
import TestPract from "../practicleTest/testPract";
import Earth from "../earth/earth";
import MyComponent from "../earth/earth";

const Home = () => {

    return (
        <>
            {/*<Earth />*/}
            <Banner/>
            <WhatDo/>
            <SectionDevelopers/>
            <SectionHowWork/>
            <SectionWhoWeWork/>
            <SectionAnalysis/>
            <SectionTestimonials/>
            {/*<SectionBlog />*/}
            <SectionContacts/>
        </>
    )
}

export default Home;