import React from "react";
import style from './sectionDescription.module.scss';

type SectionDescriptionPropsType = {
    color: string,
    text: string,
    backgroundColor?: string
}
const SectionDescription = ({color, text, backgroundColor}: SectionDescriptionPropsType) => {

    return (
        <div className={style.description} style={{color: color,
            backgroundColor: backgroundColor ? backgroundColor : ''}}>{text}</div>
    )
}

export default SectionDescription;