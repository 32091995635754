import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './app.scss';
import '../../App.css';
import Header from "../header/header";
import Main from "../main/main";
import Footer from "../footer/footer";
import {MenuContext} from "../../hooks/context";
import style from "../main/main.module.scss";
import {Outlet} from "react-router-dom";

// const Header = React.lazy(() => import('../header/header'));
// const Main = React.lazy(() => import('../main/main'));
// const Footer = React.lazy(() => import('../footer/footer'));


function App() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [path, setPath] = useState<string>('/');

    const openMenu = useCallback((open: boolean) => {
        setIsOpen(open)
    }, [])

    const changePath = useCallback((path: string) => {
        setPath(path)
    }, [])

    const menuContextValue = useMemo(() => ({isOpen, openMenu}), [isOpen, openMenu]);


    return (
            <MenuContext.Provider value={menuContextValue}>
                <div className='app'>
                    <Header/>
                    <main className={style.main}>
                        <Outlet/>
                    </main>
                    <Footer/>
                </div>
            </MenuContext.Provider>
    );
}

export default App;
