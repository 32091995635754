import React from "react";
import style from './teamMember.module.scss';
import plus from './../../../assets/images/orangePlus.png';

type TeamMemberPropsType = {
    name: string
}
const TeamMember = ({name}: TeamMemberPropsType) => {

    return(
        <div className={style.member}>
            <div className={style.member_plus}>
                <img src={plus} alt="plus"/>
            </div>
            <div className={style.member_name}>{name}</div>
        </div>
    )
}

export default TeamMember;