import React, {useState} from "react";
import style from './expertItem.module.scss';
import orangePlus from './../../../assets/images/orangePlus.png';

type ExpertItemPropsType = {
    text: string,
    title: string
}
const ExpertItem = ({text, title}: ExpertItemPropsType) => {


    const [isOpen, setIsOpen] = useState<boolean>(false);
    return (
        <div className={style.expert_item_wrapper}>
            <div className={style.block_with_name} onClick={() => setIsOpen(!isOpen)}>
                <p className={isOpen ? style.isOpen : ''}>{title}</p>
                {isOpen ? <p className={style.minus}></p>
                    : <img src={orangePlus} alt="plus"/>}
            </div>
            {isOpen && <div className={style.block_with_text}>{text}</div>}
        </div>
    )
}

export default ExpertItem;