import React from "react";
import style from './sectionTestimonials.module.scss';
import Title from "../parts/title/title";
import {
    useCommentSlider,
    useCommentSliderMini,
    useDesktop,
    useLaptop,
    useMobile,
    useMobileMin
} from "../../hooks/useAdaptives";
import Slider from "react-slick";
import user1 from './../../assets/images/testimonials/user1.jpg';
import user2 from './../../assets/images/testimonials/user2.jpg';
import user3 from './../../assets/images/testimonials/user3.jpg';
import user4 from './../../assets/images/testimonials/user_4.png';
import user5 from './../../assets/images/testimonials/user5.jpg';
import CommentWithTitle from "./commentWithTitle/commentWithTitle";
import CommentItem from "./commentItem/commentItem";


const SectionTestimonials = () => {
    // const isDesktop = useDesktop();
    // const isLaptop = useLaptop();
    // const isMobile = useMobile();
    const isMinMobile = useMobileMin();
    const isChangeSlide = useCommentSlider();
    const isChandeSlideMini = useCommentSliderMini();


    const sliderSettings = {
        className: `${style.slick_list}`,
        dots: true,
        // lazyLoad: true,
        infinite: true,
        //autoplay: true,
        // centerMode: true,
        // slidesToShow: isChandeSlideMini ? 1 : isChangeSlide ? 2 : 3,
        slidesToShow: isMinMobile ? 2 : 3,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: true,
        // centerPadding: "60px",
         // adaptiveHeight: true,
        speed: 1000,
       // autoplaySpeed: 7000,
        cssEase: "linear",
        appendDots: (dots: any) => (
            <div
                style={{
                    backgroundColor: "#ddd",
                    borderRadius: "10px",
                    padding: "10px",
                    marginTop: '-3rem'
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
    }

    const comments = [
        {text: `BrightDot delivers! Their contributions to our project with left a lasting impression. Their technical skills, dedication, and innovative solutions made a significant
         impact on the quality of the project. We're not just partners; we're friends who look forward to more exciting collaborations.`, userFoto: user1, userName: ' Krzysztof Kawa', userPosition: 'VP of Board at McCANN'},
        {text: `We partnerd with BrightDot after few unsuccessful trials with other service providers. The partnership quickly 
        became instrumental in our journey to create a cutting-edge tool for asset managers. Their commitment to quality and 
        expertise has played a key role in the successful development of our product. BrightDot has consistently meet our
         expectations even with very innovative aspects of our platform. We look forward to our continued cooperation. `, userFoto: user2, userName: 'Jeffrey Marsh', userPosition: 'Founder & CEO at Verity Platforms Inc'},
        {text: `I've had the pleasure of partnering with BrightDot, the top-tier staff augmentation company. The team I'm working with consistently delivers exceptional 
        results. Meeting and often exceeding our expectations. After some unfortunate experiences with other providers, this 
        partnership has been a game-changer for our production. Highly recommended! `, userFoto: user3, userName: 'Jo-Anne Ting', userPosition: 'CEO & President at DataSeer'},
        {text: `BrightDot has supported us in managing projects for our main clients, including MasterCard, L’Oréal, KFC,
         Starbucks, Pizza Hut, and Pekao, among others. I hold them in high regard for their skills, knowledge, and the 
         quality of their work. BrightDot consistently demonstrates a professional approach, reliability, and punctuality.
          The team fully understands our objectives and needs.  I highly recommend BrightDot as a trustworthy and
           reliable partner for effective cooperation.`, userFoto: user4, userName: 'Wojciech Borowski', userPosition: 'Group CEO at McCANN Poland'},
        {text: `We worked with BrightDot on several projects for our portfolio companies. They brought their technical know-how, dedication, and creative 
        solutions to the table, making a huge difference in our project's success. It's been a real pleasure working with them, and we're excited about future collaborations. 
        Thanks for being awesome!`, userFoto: user5, userName: 'Larissa Kessel', userPosition: 'Director of Technical Operations MRM Paris'},
    ];


    return(
        <section id={'Testimonials'} className={`${style.section_testimonials} relative_position`}>
            <Title color={'black'} text={'Testimonials'}/>

            <div className={style.slider_wrapper} id={'testimonials'}>
                <Slider {...sliderSettings}>
                        <CommentWithTitle index={1}/>
                        {comments.map((s, index) => <CommentItem key={index} index={index+1} {...s}/>)}
                </Slider>
            </div>
        </section>
    )
}

export default SectionTestimonials;