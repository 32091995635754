import React, {useState} from "react";
import style from './customSlide.module.scss';
import {SlideType} from "../sectionAnalysis";
import TeamMember from "../teamMember/teamMember";
import Technology from "../technology/technology";

type CustomSlidePropsType = {
    index: number,
    allSlide: number,
    slide: SlideType
}
const CustomSlide = ({index, allSlide, slide}: CustomSlidePropsType) => {

    const [activeFotoItem, setActiveFotoItem] = useState(slide.fotos[0]);

    const chooseActiveFotoItem = (id :number) => {
        let item = slide.fotos.filter(f => f.id === id)[0];
        item && setActiveFotoItem(item);
    }

    const pointBg = [`rgba(255, 102, 0, 1)`, `rgba(160, 160, 160, .2)`, `rgba(202, 202, 202, .57)`]

    return (
        <div className={style.custom_slide}>
            <div className={style.custom_slide_block_with_fotos}>
                <div className={style.points_block}>
                    <p style={{backgroundColor: activeFotoItem.id === 1 ? pointBg[0] : activeFotoItem.id === 2 ? pointBg[1] : pointBg[2]}}></p>
                    <p style={{backgroundColor: activeFotoItem.id === 1 ? pointBg[1] : activeFotoItem.id === 2 ? pointBg[0] : pointBg[1]}}></p>
                    <p style={{backgroundColor: activeFotoItem.id === 1 ? pointBg[2] : activeFotoItem.id === 2 ? pointBg[2] : pointBg[0]}}></p>
                </div>
                <div className={style.large_image}>
                    <img src={activeFotoItem.largeImage} alt=""/>
                </div>

                <div className={style.small_fotos_line}>
                    {slide.fotos.map((f, index)=> {
                        return ( <div key={index} className={`${style.small_foto_item} ${f.id === activeFotoItem.id ? style.active_border : style.non_active}`}
                                     onClick={() => chooseActiveFotoItem(f.id)}>
                                   <img src={f.smallImage} alt="small_image"/>
                                </div> )
                    })}
                </div>
            </div>

            <div className={style.custom_slide_block_with_info}>
                <div className={style.slide_number_block}>
                    {index} / {allSlide}
                </div>
                <h3>{slide.title}</h3>
                <div className={style.client_block}>
                    <span>Client:</span>
                    <span>{slide.client}</span>
                </div>
                <div className={style.description_block}>
                    <h5>DESCRIPTION</h5>
                    <p>{slide.description}</p>
                </div>
                <div className={style.team_block}>
                    <h5>TEAM</h5>
                    {slide.team.map((t, index) => <TeamMember key={index} name={t}/>)}
                </div>
                <div className={style.technologies_block}>
                    <h5>TECHNOLOGY</h5>
                    <div className={style.technologies_wrapper }>
                        {slide.technologies.map((t, index)=> <Technology key={index} name={t}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomSlide;