import React from "react";
import style from './itemWork.module.scss';

type ItemWorkPropsType = {
    id: number,
    title: string,
    mainText: string,
    addText: string
}
const ItemWork = ({id, title, mainText, addText}: ItemWorkPropsType) => {

    return (
        <div className={style.item_work_wrapper}>
            <h4>{title}</h4>
            <div className={style.main_text}>{mainText}</div>
            <div className={style.additional_text}>{addText}</div>
            <div className={style.number_wrapper}>{id}</div>
        </div>
    )
}

export default ItemWork;