import axios from "axios";
import {FormInputsType} from "../components/sectionContacts/blockWithForm/contactForm/contactForm";


let instance = axios.create({
    withCredentials: true,
    baseURL: '',
    headers: {
        'Access-Control-Allow-Origin': '*'
    },
});

export const contactFormAPI = {
    sendMessage(data: FormInputsType) {
        return instance.post(`https://brightdot.tech/subscribe`, data);
    },

    getAllPosts() {
        return instance.get(`http://154.12.252.80:56001/get-all`)
    },

}

