import React, {useCallback} from "react";
import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.

type propsType = {
    id: string
}
const Particle = (props: propsType) => {

    const particlesInit = useCallback(async (engine: Engine) => {
        // console.log(engine);

        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {
        // await console.log(container);
    }, []);


    return(
            <Particles id={props.id}
                height={'100%'}
                       width={'100%'}
                       style={{width: '100%', height: '100%', position: 'absolute', top: '0', left: '0'}}
                       init={particlesInit}
                       loaded={particlesLoaded}
                       options={{
                           fpsLimit: 120,
                           interactivity: {
                               detect_on: 'canvas',
                               events: {
                                   onClick: {
                                       enable: true,
                                       mode: "push",
                                   },
                                   onHover: {
                                       enable: true,
                                       mode: "grab",
                                   },
                                   resize: true,
                               },
                               modes: {
                                   grab: {
                                       distance: 140,
                                       line_linked: {
                                           opacity: 1
                                       }
                                   },
                                   bubble: {
                                       distance: 400,
                                       size: 40,
                                       duration: 2,
                                       opacity: 8,
                                       speed: 3,
                                   },
                                   push: {
                                       particles_nb: 4
                                   },
                                   repulse: {
                                       distance: 1200,
                                       duration: 0.4,
                                   },
                                   remove: {
                                       particles_nb: 2
                                   }
                               },
                           },
                           particles: {
                               number: {
                                   density: {
                                       enable: true,
                                       area: 4500,
                                   },
                                   value: 400,
                               },
                               color: {
                                   value: "#ffffff",
                               },
                               shape: {
                                   type: "circle",
                                   stroke: {
                                       width: 0,
                                       color: "#000000"
                                   },
                                   polygon: {
                                       nb_sides: 5
                                   },
                               },
                               opacity: {
                                   value: 0.5,
                                   random: false,
                                   anim: {
                                       enable: false,
                                       speed: 1,
                                       opacity_min: 0.1,
                                       sync: false
                                   }
                               },
                               size: {
                                   value: 3,
                                   random: true,
                                   anim: {
                                       enable: false,
                                       speed: 40,
                                       size_min: 0.1,
                                       sync: false
                                   }
                               },
                               links: {
                                   color: "#a4a4a4",
                                   distance: 150,
                                   enable: true,
                                   opacity: 0.4,
                                   width: 1,
                               },
                               move: {
                                   enable: true,
                                   speed: 1,
                                   direction: "none",
                                   out_mode: "out",
                                   bounce: false,
                                   random: false,
                                   straight: false,
                                   attract: {
                                       enable: false,
                                       rotateX: 600,
                                       rotateY: 1200
                                   }
                               },
                           },
                           fullScreen: false,
                           detectRetina: true,
                       }}
            />
    )
}

export default Particle;