import React from "react";
import style from './contactForm.module.scss';
import {SubmitHandler, useForm} from "react-hook-form";
import {contactFormAPI} from "../../../../api/api";
import {useHookErrors} from "../../../../hooks/useErrors";
import MessageBox from "../../../parts/messageBox/messageBox";

export type FormInputsType = {
    name: string,
    email: string,
    phone: string,
    message: string
}
const ContactForm = () => {

    const {notification, isError, isSuccess, hideNotification, setError, setSuccess} = useHookErrors();

    const {register, handleSubmit, formState: {errors}, reset} = useForm<FormInputsType>({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            message: ''
        }
    });

    const onSubmit: SubmitHandler<FormInputsType> = (data) => {
        contactFormAPI.sendMessage(data).then(res => {
            reset();
            setSuccess('We have received your letter and will contact you soon!');
        }).catch(error => {
            setError(error?.response?.data?.message || 'something wrong!');
        })
    }

    return (
        <form className={style.contact_form} onSubmit={handleSubmit(onSubmit)}>
            <div className={style.input_text_wrapper}>
                <input {...register('name', {
                    required: true,
                    pattern: /^[a-zA-Z][a-zA-Z-]*[a-zA-Z]$/
                })}
                       id="name"
                       placeholder='Name'/>
            </div>
            {errors.name?.type === "required" && (
                <p className={style.error_message}>Name is required!</p>
            )}
            {errors.name?.type === "pattern" && (
                <p className={style.error_message}>Invalid name format!</p>
            )}

            <div className={style.input_text_wrapper}>
                <input {...register('email',
                    {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                    })}
                       id="email"
                       placeholder='Email'/>
            </div>
            {errors.email?.type === "required" &&
                <p className={style.error_message}>Email is required!</p>}
            {errors.email?.type === "pattern" &&
                <p className={style.error_message}>Invalid email address!</p>}

            <div className={style.input_text_wrapper}>
                <input {...register('phone')}
                       id="phone"
                       placeholder='Phone'/>
            </div>

            <div className={style.input_text_wrapper}>
                <textarea {...register('message',
                    {
                        required: true
                    })}
                          id="message"
                          placeholder='Quick mesage'/>
            </div>
            {errors.message?.type === "required" &&
                <p className={style.error_message}>Message is required!</p>}

            <button className={style.submit_button} type={'submit'}>Send message</button>

            {(isError || isSuccess) && <div style={{marginTop: '20px'}}>
                <MessageBox notification={notification} isError={isError} autoClosing={true}
                            hideError={hideNotification} isSuccess={isSuccess}/>
            </div>}
        </form>
    )
}

export default ContactForm;