import React from "react";
import style from './nextArrow.module.scss';
import right_arrow from "../../../assets/images/analysis/next.png";

const NextArrow = (props: any) => {

    return(
        <div className={style.next_arrow} onClick={props.onClick}>
            <img src={right_arrow} alt="next_arrow"/>
        </div>
    )
}

export default NextArrow;