import React, {useState} from "react";
import style from './blockWithContacts.module.scss';
import logo from './../../../assets/images/logo.png';
import OfficeItem from "./officeItem/officeItem";

const BlockWithContacts = () => {

    const offices = [
        {id: 1, title: "Boston, MA"},
        {id: 2, title: "Jacksonville, FL"},
        {id: 3, title: "Warsaw, Poland"}
    ]

    const [activeOffice, setActiveOffice] = useState<{ id: number, title: string }>(offices[1]);

    return (
        <div className={style.block_with_contacts}>
            <div className={style.logo_block}>
                <div className={style.logo_wrapper}>
                    <div className={style.logo}><img src={logo} alt="logo"/></div>
                    <div className={style.name}>Bright<span>Dot</span></div>
                </div>
                <div className={style.contacts_block}>
                    +1 813 804 8913 <br/>+48 501 519 448
                </div>
                <div className={style.mail_block}>office@brightdot.tech</div>
            </div>
            <p className={style.offices_title}>Our offices</p>
            <div className={style.offices_wrapper}>
                {offices.map((o, index) => <OfficeItem key={index}
                                                       office={o}
                                                       activeOffice={activeOffice}
                                                       setActiveOffice={setActiveOffice}/>)}
            </div>
        </div>
    )
}

export default BlockWithContacts;