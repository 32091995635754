import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/_reset.scss';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Home from "./components/main/main";
import SectionBlog from "./components/sectionBlog/sectionBlog";
import PostPage from "./components/post/postPage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                path: '',
                element: <Home/>
            },
            // {
            //     path: 'blog',
            //     element: <SectionBlog />,
            //     // children: [
            //     //     {
            //     //      path: `:id`,
            //     //      element: <PostPage/>
            //     //     }
            //     // ]
            // },
            {
                path: '*',
                element: <Navigate to={'/'}/>
            }
        ]
    }
])

root.render(
  <React.StrictMode>
   <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
