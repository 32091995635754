import React from "react";
import style from './title.module.scss';

type TitlePropsType = {
    color: string,
    text: string,
    margin?: string
}
const Title = ({color, text, margin}: TitlePropsType) => {

    return(
        <div className={style.title_wrapper} style={{margin: margin ? margin : ''}}>
            <h2 style={{color: color}}>{text}</h2>
            <div className={style.underline}></div>
        </div>
    )
}

export default Title;