import React from "react";
import style from './cardItem.module.scss';

type CardItemPropsType = {
    image: string,
    text: string
}
const CardItem = ({image, text}: CardItemPropsType) => {

    return (
        <div className={style.card_item}>
            <div className={style.card_item_logo}>
                <img src={image} alt="image"/>
            </div>
            <div className={style.card_item_text}>{text}</div>
        </div>
    )
}

export default CardItem;