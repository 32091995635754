import React from "react";
import style from './officeItem.module.scss';
import checked from './../../../../assets/images/contacts/checked.png';
import active_checked from './../../../../assets/images/contacts/active_checked.png';

type OfficeItemPropsType = {
    office: { id: number, title: string },
    activeOffice: { id: number, title: string },
    setActiveOffice: (o: { id: number, title: string }) => void
}
const OfficeItem = ({office, activeOffice, setActiveOffice}: OfficeItemPropsType) => {

    return (
        <div className={style.office_item} onClick={() => setActiveOffice(office)}>
            <div className={style.office_item_logo}>
                <img src={activeOffice.id === office.id ? active_checked : checked}
                     alt="checked" style={{opacity: activeOffice.id === office.id ? 1 : .1}}/>
            </div>
            <div
                className={`${style.office_item_title} ${activeOffice.id === office.id ? style.active_title : ''}`}>{office.title}</div>
        </div>
    )
}

export default OfficeItem;