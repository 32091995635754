import React from "react";
import style from './banner.module.scss';
import developer from './../../assets/images/developer.png';
import star_1 from './../../assets/images/stars_1.png';
import star_2 from './../../assets/images/stars_2.png';
import star_3 from './../../assets/images/starts_3.png';
import star_4 from './../../assets/images/stars_4.png';
import star_5 from './../../assets/images/stars_5.png';
import Particle from "../practicleTest/testPract";
import {v1} from "uuid";
import LinkItem from "../header/navigation/linkItem/linkItem";

const Banner = () => {

    return (
        <section className={style.banner}>
            <Particle id={'part_1'}/>
            <div className={style.info_wrapper}>
                <div className={style.block_with_info}>
                    {/*<p className={style.title}>Connecting the Bright<span style={{fontWeight: 'bold'}}>Dots!</span></p>*/}
                    <p className={style.title}>Your bridge to the best developers!</p>
                    <h1>Connecting the Bright<span style={{fontWeight: 'bold'}}>Dots!</span></h1>
                    <p className={style.info}>We provide organizations with small to medium objective oriented teams of
                        software developers and engineers.</p>
                    {/*<button>Read more</button>*/}
                    <LinkItem link={{id: v1(), linkName: 'Read more', linkTo: 'What we do'}} classN={style.button}/>
                </div>
                <div className={style.block_with_image}>
                    <img src={developer} alt="developer image"/>
                </div>
            </div>
        </section>
    )
}

export default Banner;