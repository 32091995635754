//common custom hook for error or success message show
import {useState} from "react";

export const useHookErrors = () => {
    const [notification, setNotification] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const hideNotification = () => {
        setNotification('');
        setIsError(false);
        setIsSuccess(false)
    }

    const setError = (notification: string) => {
        setNotification(notification);
        setIsError(true);
    }

    const setSuccess = (notification: string) => {
        setNotification(notification);
        setIsSuccess(true);
    }

    return {notification, isError, isSuccess, hideNotification, setError, setSuccess}
}