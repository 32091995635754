import React, {useContext} from "react";
import style from './linkItem.module.scss';

import {Link} from "react-scroll";
import { NavLink} from "react-router-dom";

type LinkItemPropsType = {
    link: { id: string, linkName: string, linkTo: string },
    classN?: string
}
const LinkItem = ({link, classN}: LinkItemPropsType) => {

    return (
        link.linkName === 'Blog' ?

        <NavLink to={'/blog'}
                 // @ts-ignore
                 className={`${style.link} ${({isActive}) =>  isActive ? style.active  : ""}`}>Blog</NavLink>
       : <Link activeClass={style.active}
              className={classN ? classN : style.link}
              to={link.linkTo}
              spy={true}
              smooth={true}
              offset={-120}
              duration={500}>{link.linkName}</Link>
    )
}

export default LinkItem;