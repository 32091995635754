import React from "react";
import style from './sectionWhoWeWork.module.scss';
import Title from "../parts/title/title";
import SectionDescription from "../parts/sectionDescription/sectiondescription";
import {useDesktop, useLaptop, useMobile, useMobileMin} from "../../hooks/useAdaptives";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LogoItem from "./logoItem/logoItem";
import {v1} from "uuid";
import verityLogo from './../../assets/images/who_we_work_for/verity.png';
import datasirLogo from './../../assets/images/who_we_work_for/DataSeer.png';
import lorealLogo from './../../assets/images/who_we_work_for/Loreal_Paris.png';
import peugeotLogo from './../../assets/images/who_we_work_for/peugeotLogo4.png';
import vmlLogo from './../../assets/images/who_we_work_for/VML-Reveals-State-of-Marketing-In-South-Africa-.png';
import mvcanLogo from './../../assets/images/who_we_work_for/logo_2.png';
import maccanLogo from './../../assets/images/who_we_work_for/mccann-worldgroup-logo-vector.png';
import unboxtLogo from './../../assets/images/who_we_work_for/unboXt logo_transp.png';

const SectionWhoWeWork = () => {

    const isDesktop = useDesktop();
    const isLaptop = useLaptop();
    const isMobile = useMobile();
    const isMinMobile = useMobileMin();

    const sliderSettings = {
        className: `${style.slick_list}`,
        dots: false,
       // lazyLoad: true,
        infinite: true,
        autoplay: true,
        // centerMode: true,
        slidesToShow: isMinMobile ? 1 : isMobile ? 3 : isLaptop ? 4 : 6,
        slidesToScroll: 1,
        initialSlide: 0,
        // centerPadding: "60px",
       //  adaptiveHeight: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear",

      //  variableWidth: true
    }


    const textDescription = `The company was created to meet the digital development needs of major marketing agencies.
     Since then we have worked with leaders in multiple industries throughout multiple countries, from Hong-Kong through
      EU to USA. We were able to grow into an outsourcing company that is dedicated to helping companies to achieve their goals.`

    const logos = [
        {id: v1(), width: 11.875, height: 3.938, logo: verityLogo},
        {id: v1(), width: 10.188, height: 1.563, logo: datasirLogo},
        {id: v1(), width: 11.25, height: 2.813, logo: lorealLogo},
        {id: v1(), width: 16, height: 20, logo: peugeotLogo},
        {id: v1(), width: 12, height: 6, logo: vmlLogo},
        {id: v1(), width: 10.188, height: 1.563, logo: mvcanLogo},
        {id: v1(), width: 16, height: 15, logo: maccanLogo},
        {id: v1(), width: 10, height: 4, logo: unboxtLogo}
    ]

    return (
        <section id={'Who we work for'} className={`${style.section_who_we_work_for} relative_position`}>
            <Title color={'black'} text={'Who we work for'}/>
            <SectionDescription text={textDescription} color={'black'}/>
            <div className={style.slider_wrapper}><Slider {...sliderSettings}>
                    {logos.map(logo => <LogoItem key={logo.id} id={logo.id} width={logo.width} height={logo.height}
                                                 logo={logo.logo}/>)}
                </Slider></div>
        </section>
    )
}

export default SectionWhoWeWork;