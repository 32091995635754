import React from "react";
import style from './itemStep.module.scss';
import checked_icon from './../../../assets/images/checked.png';

type ItemStepPropsType = {
    text: string;
}

const ItemStep = ({text}: ItemStepPropsType) => {

    return (
        <div className={style.step}>
            <div className={style.step_image}>
                <img src={checked_icon} alt="checked_icon"/>
            </div>
            <div className={style.step_info}>{text}</div>
        </div>
    )
}

export default ItemStep;