import React, {useState} from "react";
import style from './blockWithForm.module.scss';
import message from './../../../assets/images/contacts/message.png';
import ContactForm from "./contactForm/contactForm";
import close from './../../../assets/images/contacts/close.png';

const BlockWithForm = () => {
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

    return (
        <div className={style.block_with_form}>
            <div className={`${style.form_wrapper} ${isFormOpen ? [style.show] : [style.hide]}`}>
                <div className={style.close_button} onClick={() => setIsFormOpen(false)}>
                    <img src={close} alt="close"/>
                </div>
                <div className={style.title}>How can we help?</div>
                <ContactForm/>
            </div>
            <div className={style.form_button} onClick={() => setIsFormOpen(true)}>
                <div className={style.form_button_title}>How can we help?</div>
                <div className={style.form_button_icon}>
                    <img src={message} alt="message"/>
                </div>
            </div>
        </div>
    )
}

export default BlockWithForm;