import React from "react";
import style from './commentWithTitle.module.scss';

type CommentWithTitlePropsType = {
    index: number
}
const CommentWithTitle = ({index}: CommentWithTitlePropsType) => {

    return(
        <div className={style.comment_with_title}>
            <p>What our customers think about us!</p>
        </div>
    )
}

export default CommentWithTitle;