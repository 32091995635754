import React from "react";
import style from './sectionWhatDo.module.scss';
import Title from "../parts/title/title";
import SectionDescription from "../parts/sectionDescription/sectiondescription";
import cardImage1 from './../../assets/images/cardItem1.png';
import cardImage2 from './../../assets/images/cardItem2.png';
import cardImage3 from './../../assets/images/cardItem3.png';
import CardItem from "./cardItem/cardItem";


const WhatDo = () => {
    const textDescription = `As a staff augmentation company focused on software development, we provide businesses with
     access to experienced and skilled developers. This enables organizations to bolster their in-house teams, meet project
      demands, accelerate development cycles, and leverage specialized expertise without the commitment of hiring full-time staff.`

    const cards = [
        {
            id: 1,
            image: cardImage1,
            text: 'We understand how important it is for the company to find talented individuals. This is why we hire only the best developers with significant experience and skills.'
        },
        {
            id: 2,
            image: cardImage2,
            text: 'We provide organizations with small to medium size objective oriented teams of developers required to finalize in-house projects.'
        },
        {id: 3, image: cardImage3, text: 'We work for clients from EU, USA and Australia.'}
    ]

    return (
        <section id={'What we do'}>
            <Title color={'#E7E7E7'} text={'What we do'}/>
            <SectionDescription text={textDescription} color={'#E7E7E7'}/>
            <div className={style.card_wrapper}>
                {cards.map(card => <CardItem key={card.id} text={card.text} image={card.image}/>)}
            </div>
        </section>
    )
}

export default WhatDo;