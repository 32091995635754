import React, {useEffect} from "react";
import style from './navigation.module.scss';
import LinkItem from "./linkItem/linkItem";
import {v1} from "uuid";
import {Link, NavLink, useLocation} from "react-router-dom";


const Navigation = () => {

    const links = [
        {id: v1(), linkName: 'What we do', linkTo: 'What we do'},
        {id: v1(), linkName: 'Our developers', linkTo: 'Our developers'},
        {id: v1(), linkName: 'How we work', linkTo: 'How we work'},
        {id: v1(), linkName: 'Who we work for', linkTo: 'Who we work for'},
        {id: v1(), linkName: 'Case analysis', linkTo: 'Case analysis'},
        {id: v1(), linkName: 'Testimonials', linkTo: 'Testimonials'},
        // {id: v1(), linkName: 'Blog', linkTo: 'Blog'},
    ]



    return (
        <nav className={style.navigation}>
            {links.map(link => <LinkItem key={link.id} link={link}/>)}
        </nav>
    )
}

export default Navigation;